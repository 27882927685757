import React, { useEffect } from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import ReactMarkdown from "react-markdown";

import { Layout, SEO, CTASimple } from "../components";
import { articleDateFormat, metaDateFormat } from "../misc/utils";
import { capture } from "../common/analytics";

const ArticleTemplate = ({ data }) => {
  const article = data.strapiArticle;
  const { slug } = article;
  useEffect(() => {
    capture("blog_article_viewed", { slug });
  }, [slug]);
  const publishedAt = articleDateFormat(article.published_at);
  const headerImage = getImage(article.image.localFile);
  const authorImage =
    article.author.picture && getImage(article.author.picture.localFile);
  const fallbaclHeaderImage = headerImage.images.fallback.src;
  const trimmedDescription = article.description.substring(0, 160);
  const callToAction = article.call_to_action;
  const metadata = data.site.siteMetadata;

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: article.title,
    description: trimmedDescription,
    image: fallbaclHeaderImage,
    author: {
      "@type": "Person",
      name: article.author.name,
    },
    publisher: {
      "@type": "Organization",
      name: metadata.title,
      logo: {
        "@type": "ImageObject",
        url: metadata.defaultImage,
      },
    },
    datePublished: metaDateFormat(article.published_at),
    dateModified: metaDateFormat(article.updated_at),
  };

  return (
    <Layout>
      <SEO
        title={article.title}
        description={trimmedDescription}
        schemaMarkup={schemaMarkup}
        image={fallbaclHeaderImage}
      />
      <nav className="px-8 py-8 mx-auto max-w-2xl md:max-w-7xl border-b border-gray-200">
        <ol role="list" className="flex items-center space-x-2">
          <li>
            <div>
              <Link to="/blog" className="text-blue-400 hover:text-gray-500">
                Blog
              </Link>
            </div>
          </li>
          <li>
            <div className="flex items-center">
              <svg
                className="flex-shrink-0 h-5 w-5 text-gray-300"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 20 20"
                aria-hidden="true"
              >
                <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
              </svg>
              <div className="ml-2 text-sm font-base text-gray-500">
                {article.title}
              </div>
            </div>
          </li>
        </ol>
      </nav>

      <article className="px-8 py-16 lg:py-24 mx-auto max-w-2xl md:max-w-3xl">
        <p className="flex space-x-2 text-base text-gray-500">
          <span className="font-semibold">{article.category.name}</span>
          <span className="">•</span>
          <span className="">{publishedAt}</span>
        </p>
        <h1 className="font-bold text-4xl mt-6 ">{article.title}</h1>
        <div className="flex items-center space-x-4 mt-8">
          {authorImage && (
            <GatsbyImage
              alt={article.author.name}
              className="w-14 h-14 rounded-full"
              image={authorImage}
            />
          )}
          <div>
            <h3 className="font-medium text-gray-600">{article.author.name}</h3>
            <p className="font-base text-gray-400">{article.author.role}</p>
          </div>
        </div>
        <GatsbyImage
          alt={article.title}
          className="rounded-md mt-8 w-full object-cover h-80"
          image={headerImage}
        />
        <div className="prose max-w-none mt-8">
          <ReactMarkdown>{article.content}</ReactMarkdown>
        </div>
      </article>
      {callToAction && (
        <CTASimple
          title={callToAction.title}
          description={callToAction.description}
          button={callToAction.button}
        />
      )}
    </Layout>
  );
};

export default ArticleTemplate;

export const query = graphql`
  query ArticleTemplate($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      slug
      title
      description
      content
      created_at
      published_at
      updated_at
      category {
        name
        slug
      }
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 700)
          }
        }
      }
      author {
        id
        name
        picture {
          localFile {
            childImageSharp {
              gatsbyImageData(height: 112)
            }
          }
        }
        role
      }
      call_to_action {
        title
        description
        button {
          newTab
          text
          url
        }
      }
    }

    site {
      siteMetadata {
        title
        defaultImage: image
        siteUrl
      }
    }
  }
`;
